import './App.css';
import MainContainer from "./components/MainContainer";
import SmoothScrolling from "./components/SmoothScrolling";

function App() {
  return (
      // <SmoothScrolling>
        <MainContainer/>
      // </SmoothScrolling>
  );
}

export default App;
